import loadable from '@loadable/component';
import { graphql } from 'gatsby';

export const HEADING_MAPPING = {
  CraftGQL_pageHeadings_genericHeading_BlockType: loadable(() => import('./generic')),
  CraftGQL_pageHeadings_minimalHeading_BlockType: loadable(() => import('./minimal')),
};

export const fragments = graphql`
  fragment PageHeadingsFragment on CraftGQL_pages_pages_Entry {
    headings: pageHeadings {
      typename: __typename
      ...GenericHeadingFragment
      ...MinimalHeadingFragment
    }
  }
`;
