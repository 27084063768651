import { arrayOf, shape, string } from 'prop-types';
import { graphql } from 'gatsby';
import React from 'react';

import { Renderer } from '../../components/common/renderer';
import { CONTENT_MAPPING } from '../../components/content/mapping';
import { HEADING_MAPPING } from '../../components/headings/mapping';
import { Sitemeta } from '../../components/core/sitemeta';
import { entryType, responseType } from '../../types';

export const query = graphql`
  query Page(
    $pageId: [CraftGQL_QueryArgument]!
  ) {
    response: craftgql {
      page: entry(id: $pageId) {
        title
        ...PageHeadingsFragment
        ...PageContentsFragment

        ...on CraftGQL_pages_pages_Entry {
          departments: pageDepartments {
            ... on CraftGQL_departments_department_Entry {
              color: departmentColor
            }
          }

          metadata: pageMetadata {
            ...MetadataFragment
          }
        }
      }
    }
  }
`;

const Page = ({ data }) => {
  const {
    title,
    contents,
    departments: [department],
    headings,
    metadata: [metadata],
  } = data.response.page;

  return (
    <>
      <Sitemeta
        title={title}
        {...metadata || null}
        color={department?.color}
      />
      <Renderer contents={headings} mapping={HEADING_MAPPING} />
      <Renderer contents={contents} mapping={CONTENT_MAPPING} />
    </>
  );
};

Page.propTypes = {
  ...responseType({
    page: entryType({
      title: string.isRequired,
      contents: arrayOf(shape({
        typename: string,
      })).isRequired,
      departments: arrayOf(shape({
        color: string.isRequired,
      })).isRequired,
      headings: arrayOf(shape({
        typename: string,
      })).isRequired,
    }).isRequired,
  }),
}

export default Page;
